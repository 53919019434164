// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    box-shadow: 0 0 20px rgba(0,0,0 0.2);
    background-color: #fff;
    border-radius: 8px;
}

.content-header {
    display: flex;
    flex-wrap: nowrap;
}

.content-title {
    font-size: 28px;
    margin-bottom: 15px;
    color: #333;
    flex: 1 1;
    width: 100%;
}

.contentimg {
    max-width: 20%;
    height: auto;
    margin-right: 20px;
}

p {
    line-height: 1.6;
    color: #555;
}

strong {
    font-weight: bold;
    color: #ff6600
}

.content-contentArticle {
    flex: 1 1;
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 20px;
    color: #555;
}

.writer-info {
    font-style: italic;
}

/* Add more styles as needed */`, "",{"version":3,"sources":["webpack://./src/styles/Content.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,cAAc;IACd,aAAa;IACb,oCAAoC;IACpC,sBAAsB;IACtB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,iBAAiB;AACrB;;AAEA;IACI,eAAe;IACf,mBAAmB;IACnB,WAAW;IACX,SAAO;IACP,WAAW;AACf;;AAEA;IACI,cAAc;IACd,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;IAChB,WAAW;AACf;;AAEA;IACI,iBAAiB;IACjB;AACJ;;AAEA;IACI,SAAO;IACP,eAAe;IACf,gBAAgB;IAChB,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,kBAAkB;AACtB;;AAEA,8BAA8B","sourcesContent":[".content-container {\n    max-width: 1200px;\n    margin: 0 auto;\n    padding: 20px;\n    box-shadow: 0 0 20px rgba(0,0,0 0.2);\n    background-color: #fff;\n    border-radius: 8px;\n}\n\n.content-header {\n    display: flex;\n    flex-wrap: nowrap;\n}\n\n.content-title {\n    font-size: 28px;\n    margin-bottom: 15px;\n    color: #333;\n    flex: 1;\n    width: 100%;\n}\n\n.contentimg {\n    max-width: 20%;\n    height: auto;\n    margin-right: 20px;\n}\n\np {\n    line-height: 1.6;\n    color: #555;\n}\n\nstrong {\n    font-weight: bold;\n    color: #ff6600\n}\n\n.content-contentArticle {\n    flex: 1;\n    font-size: 16px;\n    line-height: 1.6;\n    margin-bottom: 20px;\n    color: #555;\n}\n\n.writer-info {\n    font-style: italic;\n}\n\n/* Add more styles as needed */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
