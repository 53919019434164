// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.blog-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

.content-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.content-item {
    background-color: #f5f5f5;
    width: calc(30% - 20px);
    margin-bottom: 30px;
    padding: 10px;
    border: 1px solid #ddd;
    box-sizing: border-box;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease-in-out;

    &:hover {
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    }
}

.content-item h2 {
    color: #333;
}

.content-item h2 a {
    text-decoration: none;
    color: #007bff;
    transition: color 0.3s ease-in-out;
}

.content-item h2 a:hover {
    color: #0056b3;
}

.content-item p {
    color: #666;
}

.content-item strong {
    color: #ff6600
}


.content-image {
    max-width: 100%;
    height: auto;
    margin-bottom: 10px;
  }`, "",{"version":3,"sources":["webpack://./src/styles/Blog.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,cAAc;IACd,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,eAAe;IACf,8BAA8B;AAClC;;AAEA;IACI,yBAAyB;IACzB,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,sBAAsB;IACtB,sBAAsB;IACtB,wCAAwC;IACxC,uCAAuC;;IAEvC;QACI,uCAAuC;IAC3C;AACJ;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,qBAAqB;IACrB,cAAc;IACd,kCAAkC;AACtC;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI;AACJ;;;AAGA;IACI,eAAe;IACf,YAAY;IACZ,mBAAmB;EACrB","sourcesContent":[".blog-container {\n    max-width: 1200px;\n    margin: 0 auto;\n    padding: 20px;\n}\n\n.content-grid {\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: space-between;\n}\n\n.content-item {\n    background-color: #f5f5f5;\n    width: calc(30% - 20px);\n    margin-bottom: 30px;\n    padding: 10px;\n    border: 1px solid #ddd;\n    box-sizing: border-box;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n    transition: box-shadow 0.3s ease-in-out;\n\n    &:hover {\n        box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);\n    }\n}\n\n.content-item h2 {\n    color: #333;\n}\n\n.content-item h2 a {\n    text-decoration: none;\n    color: #007bff;\n    transition: color 0.3s ease-in-out;\n}\n\n.content-item h2 a:hover {\n    color: #0056b3;\n}\n\n.content-item p {\n    color: #666;\n}\n\n.content-item strong {\n    color: #ff6600\n}\n\n\n.content-image {\n    max-width: 100%;\n    height: auto;\n    margin-bottom: 10px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
