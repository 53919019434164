// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-container {
    background-color: #333;
    padding: 10px;
    text-align: center;
}

.logo-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-decoration: none;
    color: #ff6600;
}

.logo {
    max-height: 50px;
    width: auto;
    border-radius: 50%;
    border: 2px solid #ff6600;
    margin-right: 10px;
}

.logo-text {
    font-size: 24px; /* Set your desired font size */
    margin-left: 50px;
    white-space: nowrap;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    flex-grow: 1;
    font-weight: bold;
    letter-spacing: 1px;
}`, "",{"version":3,"sources":["webpack://./src/styles/Header.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,aAAa;IACb,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,2BAA2B;IAC3B,qBAAqB;IACrB,cAAc;AAClB;;AAEA;IACI,gBAAgB;IAChB,WAAW;IACX,kBAAkB;IAClB,yBAAyB;IACzB,kBAAkB;AACtB;;AAEA;IACI,eAAe,EAAE,+BAA+B;IAChD,iBAAiB;IACjB,mBAAmB;IACnB,2CAA2C;IAC3C,YAAY;IACZ,iBAAiB;IACjB,mBAAmB;AACvB","sourcesContent":[".header-container {\n    background-color: #333;\n    padding: 10px;\n    text-align: center;\n}\n\n.logo-container {\n    display: flex;\n    align-items: center;\n    justify-content: flex-start;\n    text-decoration: none;\n    color: #ff6600;\n}\n\n.logo {\n    max-height: 50px;\n    width: auto;\n    border-radius: 50%;\n    border: 2px solid #ff6600;\n    margin-right: 10px;\n}\n\n.logo-text {\n    font-size: 24px; /* Set your desired font size */\n    margin-left: 50px;\n    white-space: nowrap;\n    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);\n    flex-grow: 1;\n    font-weight: bold;\n    letter-spacing: 1px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
